const longColor = '#2ebd85'
const shortColor = '#e0294a'
const rateColor = '#f0b90b'
const priceColor = '#1d4ed8'

const common = {
  rangeSelector: {
    inputEnabled: false,
  },
  chart: { zoomType: 'x' },
  xAxis: { type: 'datetime' },
  yAxis: [{ visible: false }, { visible: false }, { visible: false }],
  title: { text: '' },
  legend: { enabled: false },
  tooltip: { shared: true },
}

const openInterest = {
  ...common,
  series: [
    {
      name: 'Open Interest',
      type: 'column',
      tooltip: { valueSuffix: ' BTC' },
      color: rateColor,
      data: [],
    },
    {
      name: 'Notional Value of Open Interest',
      tooltip: { valueSuffix: ' USDT' },
      color: 'black',
      yAxis: 1,
      marker: {
        lineWidth: 1,
        lineColor: 'black',
        fillColor: 'white',
      },
      data: [],
    },
    {
      name: 'Price',
      type: 'spline',
      yAxis: 2,
      tooltip: { valueSuffix: ' $' },
      color: priceColor,
      data: [],
    },
  ],
}

const ratioOptions = {
  ...common,
  plotOptions: { column: { stacking: 'percent' } },
  series: [
    {
      name: 'Long accounts',
      type: 'column',
      tooltip: {
        pointFormat: '<span>{series.name}</span>: <b>{point.percentage:.2f}%</b>',
      },
      color: longColor,
      data: [],
    },
    {
      name: 'Short accounts',
      type: 'column',
      tooltip: {
        pointFormat: '<span>{series.name}</span>: <b>{point.percentage:.2f}%</b>',
      },
      color: shortColor,
      data: [],
    },
    {
      name: 'Long/Short ratio',
      color: 'black',
      marker: {
        lineWidth: 1,
        lineColor: 'black',
        fillColor: 'white',
      },
      yAxis: 1,
      data: [],
    },
    {
      name: 'Price',
      type: 'spline',
      yAxis: 2,
      tooltip: { valueSuffix: ' $' },
      color: priceColor,
      data: [],
    },
  ],
}

const takerRatio = {
  ...common,
  yAxis: [{ visible: false }, { visible: false }],
  series: [
    {
      name: 'Taker Sell Volume',
      type: 'column',
      tooltip: {
        valueSuffix: ' BTC',
      },
      color: shortColor,
      data: [],
    },
    {
      name: 'Taker Buy Volume',
      type: 'column',
      tooltip: {
        valueSuffix: ' BTC',
      },
      color: longColor,
      data: [],
    },
    {
      name: 'Price',
      type: 'spline',
      yAxis: 1,
      tooltip: { valueSuffix: ' $' },
      color: priceColor,
      data: [],
    },
  ],
}

export default {
  takerRatio,
  openInterest,
  accountRatio: ratioOptions,
  topAccountRatio: ratioOptions,
  topPositionRatio: ratioOptions,
}
