<template>
  <div class="flex w-full flex-col space-y-4">
    <div class="border border-lg shadow-lg flex flex-col">
      <div class="ml-auto p-2">
        <span class="mr-5">Symbol:</span>
        <label class="border p-1">
          <select v-model="symbol" @change="updateAll()">
            <option v-for="(symbol, index) in symbols" :key="'o' + index">
              {{ symbol }}
            </option>
          </select>
        </label>
        <span class="mx-5">All Period:</span>
        <label class="border p-1">
          <select v-model="period" @change="updateAll()">
            <option v-for="(period, index) in periods" :key="'o' + index">
              {{ period }}
            </option>
          </select>
        </label>
      </div>
      <chart
        v-if="openInterest.ready"
        :options="openInterest.options"
        constructor-type="stockChart"
      />
    </div>

    <div class="border border-lg shadow-lg flex flex-col">
      <div class="ml-auto">
        <span class="mr-5">Period:</span>
        <label>
          <select
            v-model="topAccountRatio.period"
            @change="getTopAccountRatioData"
          >
            <option v-for="(period, index) in periods" :key="'o' + index">
              {{ period }}
            </option>
          </select>
        </label>
      </div>
      <chart
        v-if="topAccountRatio.ready"
        :options="topAccountRatio.options"
        constructor-type="stockChart"
      />
    </div>

    <div class="border border-lg shadow-lg flex flex-col">
      <div class="ml-auto">
        <span class="mr-5">Period:</span>
        <label>
          <select
            v-model="topPositionRatio.period"
            @change="getTopPositionRatioData"
          >
            <option v-for="(period, index) in periods" :key="'o' + index">
              {{ period }}
            </option>
          </select>
        </label>
      </div>
      <chart
        v-if="topPositionRatio.ready"
        :options="topPositionRatio.options"
        constructor-type="stockChart"
      />
    </div>

    <div class="border border-lg shadow-lg flex flex-col">
      <div class="ml-auto">
        <span class="mr-5">Period:</span>
        <label>
          <select v-model="accountRatio.period" @change="getAccountRatioData">
            <option v-for="(period, index) in periods" :key="'o' + index">
              {{ period }}
            </option>
          </select>
        </label>
      </div>
      <chart
        v-if="accountRatio.ready"
        :options="accountRatio.options"
        constructor-type="stockChart"
      />
    </div>

    <div class="border border-lg shadow-lg flex flex-col">
      <div class="ml-auto">
        <span class="mr-5">Period:</span>
        <label>
          <select v-model="takerRatio.period" @change="getTakeRatioData">
            <option v-for="(period, index) in periods" :key="'o' + index">
              {{ period }}
            </option>
          </select>
        </label>
      </div>
      <chart
        v-if="takerRatio.ready"
        :options="takerRatio.options"
        constructor-type="stockChart"
      />
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import options from './options.js'
import cloneDeep from 'lodash.clonedeep'

const RANGE = 1036800e3 // 10 days
const maps = {
  '5m': 300e3,
  '15m': 900e3,
  '30m': 1800e3,
  '1h': 3600e3,
  '2h': 7200e3,
  '4h': 14400e3,
  '6h': 21600e3,
  '12h': 43200e3,
  '1D': 86400e3,
}

const _default = {
  data: [[], [], []],
  period: '30m',
  limit: 35,
  loading: false,
  ready: false,
}

export default {
  name: 'binance-stats',

  components: {
    Chart,
  },

  data: () => ({
    symbol: 'BTCUSDT',
    symbols: ['BTCUSDT', 'ETCUSDT', 'ETHUSDT'],
    periods: ['5m', '15m', '30m', '1h', '2h', '4h', '6h', '12h', '1D'],
    period: '30m',
    openInterest: {
      ...cloneDeep(_default),
      title: 'Open Interest',
      key: 'openInterestHist',
    },
    topAccountRatio: {
      ...cloneDeep(_default),
      title: 'Top Trader Long/Short Ratio (Accounts)',
      key: 'topLongShortAccountRatio',
    },
    topPositionRatio: {
      ...cloneDeep(_default),
      title: 'Top Trader Long/Short Ratio (Positions)',
      key: 'topLongShortPositionRatio',
    },
    accountRatio: {
      ...cloneDeep(_default),
      title: 'Long/Short Ratio',
      key: 'globalLongShortAccountRatio',
    },
    takerRatio: {
      ...cloneDeep(_default),
      title: 'Taker Buy/Sell Volume',
      key: 'takerlongshortRatio',
    },
  }),

  computed: {
    limit() {
      return Math.min(
        500,
        Math.ceil(RANGE / 100 / (maps[this.period] || 1800e3)) * 100
      )
    },
  },

  methods: {
    async getBinanceData({ to, key }) {
      const base = 'https://tool.cryp-trades.com/api/binance/futures/data'
      let api = `${base}/${key}?symbol=${this.symbol}&period=${this.period}&limit=${this.limit}`
      if (to) {
        api += '&endTime=' + to
      }
      return await this.$http.get(api)
    },
    updateAll() {
      this.openInterest.period = this.period
      this.topAccountRatio.period = this.period
      this.topPositionRatio.period = this.period
      this.accountRatio.period = this.period
      this.takerRatio.period = this.period
      this.getOpenInterestData()
      this.getTopAccountRatioData()
      this.getTopPositionRatioData()
      this.getAccountRatioData()
      this.getTakeRatioData()
    },
    getOpenInterestData() {
      this.openInterest.options = cloneDeep(options.openInterest)
      this.getChartData(this.openInterest)
    },
    getTopAccountRatioData() {
      this.topAccountRatio.options = cloneDeep(options.topAccountRatio)
      this.getChartData(this.topAccountRatio)
    },
    getTopPositionRatioData() {
      this.topPositionRatio.options = cloneDeep(options.topPositionRatio)
      this.getChartData(this.topPositionRatio)
    },
    getAccountRatioData() {
      this.accountRatio.options = cloneDeep(options.accountRatio)
      this.getChartData(this.accountRatio)
    },
    getTakeRatioData() {
      this.takerRatio.options = cloneDeep(options.takerRatio)
      this.getChartData(this.takerRatio)
    },
    async afterSetExtremes(event, ref) {
      ref.loading = true
      const { chart } = event.target
      chart.showLoading('Loading data from server...')
      const { key, period, limit } = ref
      const to = event.dataMin - 1e3
      const data = await this.getBinanceData({ key, period, limit, to })
      data.forEach(ref.handler)
      for (let idx = 0; idx < 4; idx++) {
        if (ref.data[idx] && ref.data[idx].length) {
          chart.series[idx].setData(ref.data[idx])
        }
      }
      chart.hideLoading()
      chart.redraw()
      ref.loading = false
    },
    async getChartData(ref) {
      delete ref.data
      ref.data = [[], [], [], []]
      ref.ready = false
      ref.loading = true
      ref.options.title.text = ref.title
      ref.options.xAxis.range = (this.limit - 20) * maps[ref.period]
      ref.options.xAxis.events = {
        afterSetExtremes: (event) => {
          if (!ref.loading && event.min <= event.dataMin) {
            this.afterSetExtremes(event, ref)
          }
        },
      }
      const { key, period, limit } = ref
      const data = await this.getBinanceData({ key, period, limit })
      data.forEach(ref.handler)
      for (let idx = 0; idx < 4; idx++) {
        if (ref.data[idx] && ref.data[idx].length) {
          ref.options.series[idx].data.push(...ref.data[idx])
        }
      }
      ref.ready = true
      ref.loading = false
    },
  },

  mounted() {
    function _convert(val, mult = 1, fixed = 2) {
      return parseFloat((parseFloat(val) * mult).toFixed(fixed))
    }

    function _handler(el, ref) {
      const ts = el.timestamp
      const long = _convert(el.longAccount, 100)
      const short = _convert(el.shortAccount, 100)
      const ratio = _convert(el.longShortRatio)
      const price = _convert(el.price)
      ref.data[0].unshift([ts, long])
      ref.data[1].unshift([ts, short])
      ref.data[2].unshift([ts, ratio])
      ref.data[3].unshift([ts, price])
    }

    this.topAccountRatio.handler = (el) => _handler(el, this.topAccountRatio)
    this.topPositionRatio.handler = (el) => _handler(el, this.topPositionRatio)
    this.accountRatio.handler = (el) => _handler(el, this.accountRatio)

    this.takerRatio.handler = (el) => {
      const ts = el.timestamp
      this.takerRatio.data[0].unshift([ts, _convert(el.buyVol)])
      this.takerRatio.data[1].unshift([ts, _convert(el.sellVol)])
      this.takerRatio.data[2].unshift([ts, _convert(el.price)])
    }

    this.openInterest.handler = (el) => {
      const ts = el.timestamp
      const btcVal = _convert(el.sumOpenInterest)
      const usdtVal = _convert(el.sumOpenInterestValue)
      const price = _convert(el.price)
      this.openInterest.data[0].unshift([ts, btcVal])
      this.openInterest.data[1].unshift([ts, usdtVal])
      this.openInterest.data[2].unshift([ts, price])
    }

    this.getOpenInterestData()
    this.getTopAccountRatioData()
    this.getTopPositionRatioData()
    this.getAccountRatioData()
    this.getTakeRatioData()
  },
}
</script>
